import fetch from "../../utils/fetch";

export function List(query) {
  return fetch({
    url: "/importUser/page/multiQueryMore",
    method: "GET",
    params: query,
  });
}

// 行程记录
export function TripDetail(query) {
  return fetch({
    url: "/trip/list",
    method: "GET",
    params: query,
  });
}

// 疫苗类型
export function VaccineList(query) {
  return fetch({
    url: "/vaccinesType/list",
    method: "get",
    params: query,
  });
}

// 组织架构
export function Organization(query) {
  return fetch({
    url: "/secOrganize/tree",
    method: "GET",
    params: query,
  });
}

export function Transport() {
  return fetch({
    url: "/tripWay/list",
    method: "GET",
  });
}

export function ExportData(query) {
  return fetch({
    url: "/excel/exportMultiQuery",
    method: "GET",
    params: query,
    responseType: "arraybuffer",
  });
}

export function IsolateType() {
  return fetch({
    url: "/isolateType/list",
    method: "GET",
  });
}

// 常驻国家列表
export function CountryList(query) {
  return fetch({
    url: "/companyCountry/list",
    method: "GET",
  });
}


export function EditSinglePerson(mobile,params){
  return fetch({
    url:`/importUser/${mobile}/updateByMobile`,
    method:'PUT',
    data:params
  })
}