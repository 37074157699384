<template>
  <div>
    <!-- 行程记录弹窗 -->
    <el-dialog title="行程记录" :visible.sync="dialogVisible" width="30%">
      <el-scrollbar
        style="max-height: 500px; overflow-x: hidden"
        v-if="history.length"
      >
        <el-timeline>
          <el-timeline-item
            v-for="(item, index) in history"
            :key="index"
            :timestamp="formatTime(item.goTime)"
          >
            目的地：{{
              (item.abroadName ? item.abroadName : "中国") +
              (item.province || "") +
              (item.city || "") +
              (item.district || "") +
              item.aimAddress
            }}
            <p>
              交通工具：{{
                item.tripWayId == 2
                  ? "飞机"
                  : item.tripWayId == 1
                  ? "火车"
                  : "其他"
              }}
            </p>
            <p>
             出行原因:{{(item.tripReason)}}
            </p>

          </el-timeline-item>
        </el-timeline>
      </el-scrollbar>
      <p v-else style="color: #999; text-align: center">该用户暂无行程记录</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { TripDetail } from "@/api/person/status";
import format from "@/mixins/format";
export default {
  props: {},
  data() {
    return {
      dialogVisible: false,
      history: [],
    };
  },
  mixins: [format],
  methods: {
    show(mobile) {
      if (!mobile) {
        this.$message.error("该用户未提交记录");
        return;
      }

      TripDetail({ mobile: mobile }).then((res) => {
        this.dialogVisible = true;
        this.history = res.data.data;
      });
    },
  },
};
</script>

<style>
</style>